<template>
    <div style="height: 100vh;width: 100vw; margin: 0">
      <div class="content">
        <div>
          <img src="../../public/img/logo.png" class="tplog">
        </div>
        <div class="tpp-m"><img src="../../public/img/图2.png" class="tpp"></div>


        <!-- k座停车场 -->
        <div class="car">
          <img src="../../public/img/图4.png" class="tpk">
          <div class="carc">
              <img src="../../public/img/图3.png" class="tpcar">
            <Mynum class="num" :quantity="quantity" :delayed="delayed" :number="number" :time="time" :timing="timing"
              @childEvent="handleChildEvent" />
          </div>

          <img src="../../public/img/图5.png" class="tpx" />
        </div>


      </div>
    </div>
</template>

<script>
import Mynum from '../components/nub.vue'
import { defineComponent } from 'vue'
import axios from 'axios';

export default defineComponent({
  data() {
    return {

      quantity: 2, // 默认2个
      delayed: false, // 从零变
      number: 0,// 数字
      time: 1000, // 过度时间
      timing: "ease", // 过度动画速度

    };
  },
  components: {
    Mynum
  },
  mounted() {
    // 初始请求并启动定时器
    this.startFetching();
  },

  computed: {

  },
  watch: {

  },
  methods: {
    add() {
      this.number += 1
    },

    subtraction() {
      this.number -= 1
    },
    handleChildEvent(value) {
      this.number = value; // 处理从子组件接收到的数据
    },
    fetchData() {
      axios.get('/api/count')
        .then(response => {
          // 确保返回结果的格式是您期望的
          if (response.data && response.data.result && response.data.result.k_available !== undefined) {
            this.number = response.data.result.k_available;
          } else {
            console.error('Invalid response format:', response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
        });

    },
    // 调用fetchData方法，每5秒钟请求一次
    startFetching() {
      this.fetchData();
      setInterval(() => {
        this.fetchData();
      }, 5000);
    }

  },
})
</script>

<style scoped>
.content {
  height: 100%;
  width: 100%;
  background-color: #243d93;
  display: flex;
  flex-direction: column;
}

.tplog {
  width: 45%;
  display: block;
  padding-top: 15px;
  margin-left: 5%;
}

.tpp-m {
  display: flex;
  padding-left: 5%;
}

.tpp {
  width: 80%;
  margin-top: 15%;

}

.tps {
  width: 180px;
  margin-left: -180px;
  margin-top: 5px;

}

.car {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.carc {
  display: flex;
  padding: 50px 0;
  margin-top: 4%;
  margin-left: 5%;
  margin-right: 5%;
  background: #000;
}


.tpcar {
    width: 50%;
    height: auto;
}

.num {
  display: flex;
    width: 50%;
  background-color: black;
  justify-content: center;
}

.tpk {
  width: 45%;
  margin-top: 10%;
  padding-left: 5%;
}

.tpx_main{
    justify-content: center;
    align-items: center;
}

.tpx {
  position: absolute;
  bottom: 5%;
  width: 80%;
  margin-left: 5%;
}
</style>
