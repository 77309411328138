<template>
  <div class="content">

    <div class="record">
      <div v-for="(item, index) in list" :key="index" class="item border">
        <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
          {{ indexs }}
        </div>
      </div>
    </div>
  </div>


  <!-- <div @click="randomNumber" style="width: 250px; height: 100px; background: #ccc; margin-top: 100px">
    点击生成随机数
  </div> -->

</template>

<script>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  data() {
    return {

      num: 0,

    };
  },
  props: {
    quantity: {
      type: Number,
      default: 2,
    },
    delayed: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Number,
      default: 2000,
    },
    number: {
      type: Number,
      required: true,
      default: 888,
    },
    timing: {
      type: String,
      default: "ease",
    },
  },

  computed: {
    numArr() {
      if (this.num) {
        return (this.num + "").split("");
      } else {
        return new Array(this.number.length).fill(0);
      }
    },
    list() {
      let arr = [];
      let len = this.numArr.length;
      if (this.quantity && this.quantity > len) {
        arr = [...new Array(this.quantity - len).fill(0), ...this.numArr];
      } else {
        arr = this.numArr;
      }
      return arr;
    },
  },
  methods: {
    randomNumber() {
      this.number = Math.floor(Math.random() * (99 - 1 + 1)) + 1;
    },
    style(e) {
      return {
        transform: `translateY(-${e * 100}%)`,
        transition: this.time + "ms",
        transitionTimingFunction: this.timing,
      };
    },
  },
  created() {
    if (this.delayed) {
      setTimeout(() => {
        this.num = this.number;
      }, 1000);
    } else {
      this.num = this.number;
    }
  },

  watch: {
    number: {
      handler(newValue, oldValue) {
        // newValue 新的值，oldValue变化前的值
        console.log(newValue, oldValue);
        if (newValue >= 99) {

          this.num = 99
          this.$emit('childEvent', 99)
        }
        this.num = newValue;
      },
    },
  },
});
</script>

<style lang="scss" scoped>
* {
  touch-action: pan-y;

}



.record {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.item {
  color: #00fd3b;
  font-weight: 700;
  text-align: center;
  overflow: hidden;
}

//宽度大于多少时

@media (min-width: 300px) {
  .item {
    height: 120px;
    font-size: 145px;
    line-height: 120px;
  }
}

@media (min-width: 680px) and (max-width:1024px) {
  .item {
    height: 160px;
    font-size: 240px;
    line-height: 160px;
  }
}

@media (min-width: 1025px) {
  .item {
    height: 190px;
    font-size: 280px;
    line-height: 190px;
  }
}

.border {}
</style>
